<template>
	<div data-route>
		<page-header
			heading="Options"
		/>
		<div data-element="main">
			<list-view
				:list="list"
				@actionClick="onActionClick"
			/>
		</div>
		<portal
			to="modal"
			v-if="isBidExplanationsModalVisible"
		>
			<modal-inner
				@close="toggleBidExplanationsModal"
				heading="Reusable bid explanations"
			>
				<edit-teacher-options-list-modal
					entityName="Explanation"
					:options="teacherProfile.bidExplanations"
					:isWaiting="isWaiting"
					@onAddNew="handleAddExplanation"
					@onDelete="handleDeleteExplanation"
					@onSave="handleSave"
					@close="toggleBidExplanationsModal"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="isBidHintsModalVisible"
		>
			<modal-inner
				@close="toggleBidHintsModal"
				heading="Reusable bid hints"
			>
				<edit-teacher-options-list-modal
					entityName="Hint"
					:options="teacherProfile.bidHints"
					:isWaiting="isWaiting"
					@onAddNew="handleAddHint"
					@onDelete="handleDeleteHint"
					@onSave="handleSave"
					@close="toggleBidHintsModal"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="isDeclarerHintsModalVisible"
		>
			<modal-inner
				@close="toggleDeclarerHintsModal"
				heading="Reusable declarer hints"
			>
				<edit-teacher-options-list-modal
					entityName="Declarer Hint"
					:options="teacherProfile.declarerHints"
					:isWaiting="isWaiting"
					@onAddNew="handleAddDeclarerHint"
					@onDelete="handleDeleteDeclarerHint"
					@onSave="handleSave"
					@close="toggleDeclarerHintsModal"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="isSummaryMessagesModalVisible"
		>
			<modal-inner
				@close="toggleSummaryMessagesModal"
				heading="Reusable summary messages"
			>
				<edit-teacher-options-list-modal
					entityName="Message"
					:options="teacherProfile.resultMessages"
					:isWaiting="isWaiting"
					@onAddNew="handleAddMessage"
					@onDelete="handleDeleteMessage"
					@onSave="handleSave"
					@close="toggleSummaryMessagesModal"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<route>
	{
		"meta": {
			"isTeacherOnly": true
		}
	}
</route>

<script>

	import PageHeader from '@/components/ui/PageHeader';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import ListView from '@/components/ui/views/listView/ListView';
	import EditTeacherOptionsListModal from '@/components/options/EditTeacherOptionsListModal';
	import actionClick from '@/mixins/actionClick';
	import api from '@/services/api';

	export default {
		metaInfo: {
			title: 'Options'
		},
		components: {
			PageHeader,
			ModalInner,
			ListView,
			EditTeacherOptionsListModal
		},
		mixins: [actionClick],
		data: () => ({
			teacherProfile: null,
			isBidExplanationsModalVisible: false,
			isBidHintsModalVisible: false,
			isDeclarerHintsModalVisible: false,
			isSummaryMessagesModalVisible: false,
			isWaiting: undefined
		}),
		computed: {
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/options',
						text: 'Options'
					}
				];
			},
			list () {
				return [
					{
						text: 'Reusable bid explanations',
						actionId: 'toggleBidExplanationsModal'
					},
					{
						text: 'Reusable bid hints',
						actionId: 'toggleBidHintsModal'
					},
					{
						text: 'Reusable declarer hints',
						actionId: 'toggleDeclarerHintsModal'
					},
					{
						text: 'Reusable summary messages',
						actionId: 'toggleSummaryMessagesModal'
					}
				];
			}
		},
		mounted () {
		},
		created () {
			this.getTeacherProfile();
			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});
		},
		methods: {
			async getTeacherProfile () {
				const response = await api.teacher.getCurrentUserTeacherProfile();
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.teacherProfile = response;
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async updateTeacherProfile (data) {
				const response = await api.teacher.updateCurrentUserTeacherProfile({
					teacher: data
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}

				this.teacherProfile = response;
			},
			async handleSave (key) {
				this.isWaiting = key;
				await this.updateTeacherProfile(this.teacherProfile);
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'Saved successfully.'
				});
			},
			toggleBidExplanationsModal () {
				this.isBidExplanationsModalVisible = !this.isBidExplanationsModalVisible;
			},
			async handleAddExplanation (explanation) {
				this.isWaiting = 'saveExplanation';
				await this.updateTeacherProfile({
					...this.teacherProfile,
					bidExplanations: [explanation, ...this.teacherProfile.bidExplanations]
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The bid explanation has been added.'
				});
			},
			async handleDeleteExplanation (indexToDelete) {
				this.isWaiting = String(indexToDelete);
				await this.updateTeacherProfile({
					...this.teacherProfile,
					bidExplanations: this.teacherProfile.bidExplanations.filter(
						(_, index) => index !== indexToDelete
					)
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The bid explanation has been deleted.'
				});
			},
			toggleBidHintsModal () {
				this.isBidHintsModalVisible = !this.isBidHintsModalVisible;
			},
			async handleAddHint (hint) {
				this.isWaiting = 'saveHint';
				await this.updateTeacherProfile({
					...this.teacherProfile,
					bidHints: [hint, ...this.teacherProfile.bidHints]
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The bid hints has been added.'
				});
			},
			async handleDeleteHint (indexToDelete) {
				this.isWaiting = String(indexToDelete);
				await this.updateTeacherProfile({
					...this.teacherProfile,
					bidHints: this.teacherProfile.bidHints.filter(
						(_, index) => index !== indexToDelete
					)
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The bid hint has been deleted.'
				});
			},
			toggleDeclarerHintsModal () {
				this.isDeclarerHintsModalVisible = !this.isDeclarerHintsModalVisible;
			},
			async handleAddDeclarerHint (hint) {
				this.isWaiting = 'saveDeclarerHint';
				await this.updateTeacherProfile({
					...this.teacherProfile,
					declarerHints: [hint, ...this.teacherProfile.declarerHints]
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The declarer hints has been added.'
				});
			},
			async handleDeleteDeclarerHint (indexToDelete) {
				this.isWaiting = String(indexToDelete);
				await this.updateTeacherProfile({
					...this.teacherProfile,
					declarerHints: this.teacherProfile.declarerHints.filter(
						(_, index) => index !== indexToDelete
					)
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The declarer hint has been deleted.'
				});
			},
			toggleSummaryMessagesModal () {
				this.isSummaryMessagesModalVisible = !this.isSummaryMessagesModalVisible;
			},
			async handleAddMessage (message) {
				this.isWaiting = 'saveMessage';
				await this.updateTeacherProfile({
					...this.teacherProfile,
					resultMessages: [message, ...this.teacherProfile.resultMessages]
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The message has been added.'
				});
			},
			async handleDeleteMessage (indexToDelete) {
				this.isWaiting = String(indexToDelete);
				await this.updateTeacherProfile({
					...this.teacherProfile,
					resultMessages: this.teacherProfile.resultMessages.filter(
						(_, index) => index !== indexToDelete
					)
				});
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The message has been deleted.'
				});
			}
		}
	};
</script>
